<template>
  <b-card title="Generate SPP">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-2" @submit.prevent>
            <b-row>
              <b-col cols="6" sm="6">
                <b-form-group label="Tanggal Awal" label-for="startdate">
                  <validation-provider #default="{ errors }" name="startdate">
                    <b-form-input id="startdate" v-model="dataParent.startdate" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Tanggal Awal" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Tanggal Akhir" label-for="enddate">
                  <validation-provider #default="{ errors }" name="enddate">
                    <b-form-input id="enddate" v-model="dataParent.enddate" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Tanggal Akhir" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
                  <b-form-group label="Tahun" label-for="academic_year_id" :state="errors.length > 0 ? false : null">
                    <v-select id="academic_year_id" v-model="dataParent.academic_year_id"
                      :reduce="(academic_year_id) => academic_year_id.real_id" placeholder="Pilih Tahun"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listacademicyear" label="name" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6" sm="6">
                <validation-provider #default="{ errors }" name="class_id">
                  <b-form-group label="Tingkat" label-for="class_id" :state="errors.length > 0 ? false : null">
                    <v-select id="class_id" v-model="dataParent.class_id" :reduce="(class_id) => class_id.real_id"
                      placeholder="Pilih Tingkat" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listclass"
                      label="level_desc" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- <validation-provider #default="{ errors }" name="payment_type" rules="required">
                  <b-form-group label="Jenis Pembayaran" label-for="payment_type"
                    :state="errors.length > 0 ? false : null">
                    <v-select id="payment_type" v-model="dataParent.payment_type"
                      :reduce="(payment_type) => payment_type.real_id" placeholder="Pilih Jenis Pembayaran"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listpaymenttype" label="name" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider> -->
                <b-form-group label="Potongan" label-for="diskon">
                  <validation-provider #default="{ errors }" name="startdate">
                    <b-form-input id="diskon" v-model="dataParent.diskon" type="number"
                      :state="errors.length > 0 ? false : null" placeholder="Potongan" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                    @click="GetAllData"><feather-icon icon="DatabaseIcon" />
                    View
                  </b-button>
                  <b-button variant="gradient-info" class="btn-icon" @click="toGenerate">
                    <feather-icon icon="DatabaseIcon" /> Generate
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
    <table class="table" id="generatetable">
      <thead>
        <tr>
          <th>No</th>
          <th>NISN</th>
          <th>Nama</th>
          <th>Kelas</th>
          <th><input type="checkbox" name="select_all" value="1" id="example-select-all"></th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import vSelect from 'vue-select'

import axios from 'axios';
import $ from 'jquery';

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mounted() {
    $('#generatetable').DataTable({
      processing: true,
      responsive: true,
    });
  },
  data: function () {
    return {
      products: [],
      dataParent: {
        startdate: '',
        enddate: '',
        academic_year_id: '',
        class_id: '',
        diskon: 0,
      },
      DataProduct: [],
      listacademicyear: [],
      listclass: [],
      listpaymenttype: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getacademicyear(),
      this.getclass(),
      this.getpaymenttype()
  },
  methods: {
    async getpaymenttype() {
      try {
        const response = await this.$http.get('/paymentmastertypes')
        this.listpaymenttype = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getclass() {
      try {
        const response = await this.$http.get('/levels')
        this.listclass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getacademicyear() {
      try {
        const response = await this.$http.get('/academicyears')
        this.listacademicyear = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Employee.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },

    async getAllEmployee() {
      try {
        const response = await this.$http.get('/generatespp')
        this.rows = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },

    async toGenerate() {

    },
    async GetAllData() {
      try {
        var SD = document.getElementById('startdate');
        var ED = document.getElementById('enddate');
        const sdate = SD.value.substring(0, 7);
        const edate = ED.value.substring(0, 7);
        const response = await this.$http.get('/students')
        console.log(response);
        var DataRespone = response.data.data;
        var number = 1;
        var RecapTemp = [];
        $.each(DataRespone, function (i, item) {
          var DataDetail = DataRespone[i].details;
          var JsonData = {
            "no": number,
            "nisn": DataRespone[i].nisn,
            "name": DataRespone[i].name,
            "class": DataRespone[i].academicclassstudent.description_class.description
          }
          number++;
          RecapTemp.push(JsonData);
        });
        this.DataProduct = this.RecapTemp;
        $('#generatetable').DataTable().destroy();
        var myTable = $('#generatetable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "data": [],
          "columns": [{
            "title": "No",
            "data": "no"
          }, {
            "title": "NISN",
            "data": "nisn"
          }, {
            "title": "Nama",
            "data": "name"
          }, {
            "title": "Kelas",
            "data": "class"
          }],
          'columnDefs': [{
            'targets': 4,
            'searchable': false,
            'orderable': false,
            'render': function (data, type, full, meta) {
              return '<input type="checkbox" name="id[]" value="' + $('<div/>').text(data).html() + '">';
            }
          }],
          'order': [[1, 'asc']]
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          console.log(value);
          myTable.row.add(value);
        });
        myTable.draw();

        var table = $('#generatetable').DataTable();
        $('#example-select-all').on('click', function () {
          var rows = table.rows({ 'search': 'applied' }).nodes();
          $('input[type="checkbox"]', rows).prop('checked', this.checked);
        });

        $('#generatetable tbody').on('change', 'input[type="checkbox"]', function () {
          if (!this.checked) {
            var el = $('#example-select-all').get(0);
            if (el && el.checked && ('indeterminate' in el)) {
              el.indeterminate = true;
            }
          }
        });

        // Handle form submission event
        $('#frm-example').on('submit', function (e) {
          var form = this;
          table.$('input[type="checkbox"]').each(function () {
            if (!$.contains(document, this)) {
              if (this.checked) {
                $(form).append(
                  $('<input>')
                    .attr('type', 'hidden')
                    .attr('name', this.name)
                    .val(this.value)
                );
              }
            }
          });
        });

      } catch (err) {
        this.showToast('info', 'top-center', 'Data Tidak Tersedia')
        console.log(this.err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
