var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Generate SPP"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Awal","label-for":"startdate"}},[_c('validation-provider',{attrs:{"name":"startdate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"startdate","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Awal"},model:{value:(_vm.dataParent.startdate),callback:function ($$v) {_vm.$set(_vm.dataParent, "startdate", $$v)},expression:"dataParent.startdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Akhir","label-for":"enddate"}},[_c('validation-provider',{attrs:{"name":"enddate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"enddate","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Akhir"},model:{value:(_vm.dataParent.enddate),callback:function ($$v) {_vm.$set(_vm.dataParent, "enddate", $$v)},expression:"dataParent.enddate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"academic_year_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tahun","label-for":"academic_year_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"academic_year_id","reduce":function (academic_year_id) { return academic_year_id.real_id; },"placeholder":"Pilih Tahun","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listacademicyear,"label":"name"},model:{value:(_vm.dataParent.academic_year_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "academic_year_id", $$v)},expression:"dataParent.academic_year_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6","sm":"6"}},[_c('validation-provider',{attrs:{"name":"class_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tingkat","label-for":"class_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"class_id","reduce":function (class_id) { return class_id.real_id; },"placeholder":"Pilih Tingkat","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listclass,"label":"level_desc"},model:{value:(_vm.dataParent.class_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "class_id", $$v)},expression:"dataParent.class_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Potongan","label-for":"diskon"}},[_c('validation-provider',{attrs:{"name":"startdate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"diskon","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Potongan"},model:{value:(_vm.dataParent.diskon),callback:function ($$v) {_vm.$set(_vm.dataParent, "diskon", $$v)},expression:"dataParent.diskon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.GetAllData}},[_c('feather-icon',{attrs:{"icon":"DatabaseIcon"}}),_vm._v(" View ")],1),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"gradient-info"},on:{"click":_vm.toGenerate}},[_c('feather-icon',{attrs:{"icon":"DatabaseIcon"}}),_vm._v(" Generate ")],1)],1)],1)],1)],1)],1)],1)],1),_c('table',{staticClass:"table",attrs:{"id":"generatetable"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("NISN")]),_c('th',[_vm._v("Nama")]),_c('th',[_vm._v("Kelas")]),_c('th',[_c('input',{attrs:{"type":"checkbox","name":"select_all","value":"1","id":"example-select-all"}})])])]),_c('tbody')])],1)}
var staticRenderFns = []

export { render, staticRenderFns }